import type { PageLoad } from './$types';
import { createUrl } from '$lib/utils/url';

interface PostMetadata {
    title: string;
    description: string;
    date: Date;
    author: string;
    tags: string;
    published: boolean;
}

interface Post {
    slug: string;
    metadata: PostMetadata;
}

interface CleanPost {
    title: string;
    date: Date;
    slug: string;
    tags: string[];
}

export const prerender = true;

export const load: PageLoad = async () => {
    // Import all markdown files
    const posts = await Promise.all(
        Object.entries(import.meta.glob('/src/posts/*.md')).map(async ([path, resolver]) => {
            const { metadata = {} } = await resolver() as any;
            const defaultMetadata = {
                title: path.split('/').pop()?.replace('.md', '') || 'Untitled Post',
                description: '',
                date: new Date(import.meta.url.split('/').pop()?.match(/\d{4}-\d{2}-\d{2}/)?.[0] || new Date().toISOString().split('T')[0]),
                author: '',
                tags: '',
                published: true
            };
            const mergedMetadata = { ...defaultMetadata, ...metadata };
            const slug = path.split('/').pop()?.replace('.md', '');
            return { slug, metadata: mergedMetadata } as Post;
        })
    );

    // Filter published posts and sort by date
    const publishedPosts = posts
        .filter(post => post.metadata.published)
        .sort((a, b) => new Date(b.metadata.date).getTime() - new Date(a.metadata.date).getTime());

    // Function to get recent posts by tag
    const getRecentPostsByTag = (tag: string, limit: number = 2): CleanPost[] => {
        return publishedPosts
            .filter(post => {
                const postTags = typeof post.metadata.tags === 'string' 
                    ? post.metadata.tags.split(',').map((t: string) => t.trim().toLowerCase())
                    : [];
                return postTags.includes(tag.toLowerCase());
            })
            .slice(0, limit)
            .map(post => ({
                title: post.metadata.title,
                date: post.metadata.date,
                slug: post.slug,
                tags: typeof post.metadata.tags === 'string' 
                    ? post.metadata.tags.split(',').map((t: string) => t.trim())
                    : []
            }));
    };

    return {
        tagSections: {
            thoughts: getRecentPostsByTag('thoughts'),
            ai: getRecentPostsByTag('ai'),
            virtual: getRecentPostsByTag('virtual')
        },
        metadata: {
            title: 'mind=computer',
            description: 'Thoughts on programming, technology, and more.',
            canonical: createUrl(''),
            openGraph: {
                title: 'mind=computer',
                description: 'Thoughts on programming, technology, and more.',
                type: 'website'
            }
        }
    };
};