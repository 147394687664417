<script lang="ts">
    interface Post {
        title: string;
        date: Date;
        slug: string;
        tags: string[];
    }

    export let post: Post;

    const formatDate = (date: Date): string => {
        return new Date(date).toISOString().split('T')[0];
    };
</script>

<a 
    href="/{post.slug}" 
    class="block w-full p-4  pt-3 border border-mc-grey-600 rounded-xl hover:bg-mc-grey-800 transition-colors"
>
    <time class="text-xs text-mc-grey-400  leading-none  m-0" datetime={formatDate(post.date)}>{formatDate(post.date)}</time>
    <h3 class="text-lg font-semibold text-white">{post.title}</h3>
    <div class="text-xs flex flex-wrap items-center gap-2 gap-y-0 mt-1 text-sm text-zinc-400">
        <div class="flex gap-2">
            {#each post.tags as tag}
                <span class="text-zinc-500">#{tag}</span>
            {/each}
        </div>
    </div>
</a> 